@use "@angular/material" as mat;
@import "./variables.scss";

$ukc-palette: (
   50: #FDE6EB,
   100: #e50307,
   200: #459BA6,
   300: #F26D8B,
   400: #F04E72,
   500: #ED2F59,
   600: #EB2A51,
   700: #000000,
   800: #E51D3E,
   900: #E0122E,
   A100: #FFFFFF,
   A200: #FFDBDF,
   A400: #FFA8B2,
   A700: #FF8F9B,
   contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
   ),
);

.event-font {
   font-weight: 500;
   font-size: 14px !important;
}


$my-primary: mat.define-palette($ukc-palette, 500);
$my-accent: mat.define-palette($ukc-palette, 200);
$my-warn: mat.define-palette($ukc-palette, 100);
// $my-warn: mat.define-palette(mat.$red-palette);


$my-theme: mat.define-light-theme((color: (primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn,
         ),
      ));
@include mat.all-component-themes($my-theme);

html,
body {
   height: 100%;
}

body {
   padding: 0;
   margin: 0;
   font-family: Roboto, "Helvetica Neue", sans-serif;
}

.success-snackbar {
   background-color: #43a547;
}

.warning-snackbar {
   background: $yellow-warn;
}

.error-snackbar {
   background: $color-error;
}

.mat-simple-snackbar-action {
   color: white !important;
}



.inv-list {

   // box-sizing: border-box;
   @media (min-width: 1025px) {
      box-shadow: 0 0 14px 4px rgba($color: #091e42, $alpha: 0.15);
      border-radius: 4px;
   }

   padding-top: 0 !important;
   overflow: hidden;
}

.m-4 {
   margin: 24px;
}

.mx-4 {
   margin-left: 24px;
   margin-right: 24px;
}

.mb-2 {
   margin-bottom: 12px;
}

.p-4 {
   padding: 24px;
}

.px-4 {
   padding-left: 24px;
   padding-right: 24px;
}

.inv-list:empty {
   display: none;
}

.inv-list small {
   font-size: 9pt;
   opacity: 0.6;
}

.inv-list .mat-list-item.deleted {
   border-left: 2px solid red;
   color: rgb(175, 175, 175);
}

.infoblock {
   margin: 16px 0;
   padding: 24px;

   &.warning {
      background-color: rgba($color: #ff0000, $alpha: 0.03);
      color: rgba($color: #ff0000, $alpha: 0.75);
      border: 1px solid rgba($color: #ff0000, $alpha: 0.33);
   }

   &.notice {
      background-color: rgba($color: #5fc1d1, $alpha: 0.03);
      color: rgba($color: #5fc1d1, $alpha: 0.95);
      border: 1px solid rgba($color: #5fc1d1, $alpha: 0.33);
   }

   &.info {
      background-color: rgba($color: #393c46, $alpha: 0.03);
      color: rgba($color: #393c46, $alpha: 1);
      border: 1px solid rgba($color: #393c46, $alpha: 0.33);
   }
}

mat-form-field {
   width: 100%;
   min-width: 50px;
}

.dialog-close-btn {
   position: relative;
   float: right;
   top: -10px;
   right: -10px;
}

a.link {
   color: map-get($ukc-palette, 700);
}

.sectionName {
   h2 {
      color: rgba(0, 0, 0, 0.706);
      margin-bottom: 5px;
   }

   mat-divider {
      margin-bottom: 24px;
   }
}

mat-sidenav,
.mat-drawer-inner-container {
   overflow: hidden !important;
}

.mat-prefix-fix {
   font-size: 8pt !important;
   top: -3px;
   position: relative;
   left: 10px;
}

.main-container {
   margin: 24px;
}

.sidebar-outlet {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   z-index: 1;
}

::-webkit-scrollbar {
   width: 4px;
   overflow-y: scroll;
   // box-shadow: inset 0 0 4px rgb(158, 157, 157);
}

::-webkit-scrollbar-thumb {
   //  background:#7cad41;
   background-color: currentcolor;
   max-height: 24px;
   border-radius: 10px;
}

mat-action-list {
   mat-list-item.list-header {
      pointer-events: none;

      div {
         flex: 1;
         min-width: 50px;
         padding: 10px 20px;
         font-weight: bold;
         font-size: 10pt;
         cursor: default;
         color: #000;
         justify-items: center !important;
      }
   }

   mat-list-item.list-item {
      height: 60px !important;

      div {
         flex: 1;
         min-width: 50px;
         padding: 10px 10px;

         .mat-line {
            font-size: 12pt;
            color: rgba(0, 0, 0, 0.549);
            font-weight: 500;
         }
      }

   }
}

.content {
   padding: 16px 24px;
}

.evCursor {
   cursor: pointer !important;
}

.section-subtitle {
   color: #6f6f6f;
   font-weight: 500 !important;
   margin: 0 !important;
}

.toolbox {
   margin: 16px 24px;
   display: flex;
   align-items: center;
   flex-direction: row;
}

.dialog-close-btn {
   position: relative;
   float: right;
   top: -10px;
   right: -10px;
}

.info-color {
   color: #393c46;
}

.fc-next-button,
.fc-prev-button,
.fc-today-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listWeek-button {
   background-color: $main-bg !important;
   border: none !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
   background-color: #393c46d0 !important;
}